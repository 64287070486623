import apiClient from '@/clients/apiClient';

export const getCustomerList = (
  page = 1,
  pageSize = 10,
  search = '',
  isVerified = '',
  sortBy = '',
  orderBy = ''
) =>
  apiClient.get(
    `users?Page=${page}&PageSize=${pageSize}&Search=${search}&IsVerified=${isVerified}&Sort.Sort_By=${sortBy}&Sort.Order_By=${orderBy}`
  );

export const getUnpaginatedCustomerList = (search = '') =>
  apiClient.get(`users/lite?Search=${search}`);

export const getCustomersNeedingVerification = (search = '') =>
  apiClient.get(`users/needing-verification?Search=${search}`);

export const getCustomerProfile = (id) => apiClient.get(`users/${id}`);

export const getCustomerLoans = (id) =>
  apiClient.get(`/users/${id}/loan-requests`);

export const createCustomer = (data) => apiClient.post('users', data);

export const updateCustomer = (data, id) => apiClient.put(`users/${id}`, data);

export const getCustomersCount = () => apiClient.get('users/entities/count');

export const getCustomerBusinesses = (id) =>
  apiClient.get(`users/${id}/businesses`);

export const suspendCustomer = (userId) =>
  apiClient.patch(`users/${userId}/suspend`);

export const reactivateCustomer = (userId) =>
  apiClient.patch(`users/${userId}/re-enable`);

export const verifyCustomerBVN = (userId) =>
  apiClient.post(`users/${userId}/bvn-information/verify`, {});

export const forceResetUserPassword = (userId) =>
  apiClient.post(`users/${userId}/force-password-reset`);

export const uploadCustomerDocument = (payload) =>
  apiClient.post(`users/${payload.userId}/profile/id-card`, payload.data);

export const getStates = () => apiClient.get('/users/states');

export const getLgas = (stateId) =>
  apiClient.get(`users/states/${stateId}/lgas`);

export const uploadCustomerOtherDocument = (payload) =>
  apiClient.post(`users/${payload.userId}/profile/documents`, payload.data);
