import { corporateServices } from '@/services';

const {
  createCorporate,
  getCorporateList,
  getCorporateProfile,
  getCorporateVendors,
  getCorporateFacilities,
  getCorporateDocuments,
  editCorporate,
  getCorporateOverview,
  getAllVerifiedCorporate,
  verifyCorporate,
  getUnVerifiedCorporate,
  mergeCorporate,
  getSimilarUnVerifiedCorporate,
  getSimilarVerifiedCorporate,
} = corporateServices;

export default {
  namespaced: true,

  state: () => ({
    corporateList: [],
    currentCorporate: null,
  }),
  getters: {
    corporateList: ({ corporateList }) => corporateList,
    currentCorporate: ({ currentCorporate }) => currentCorporate,
  },

  mutations: {
    SET_CORPORATE_LIST: (state, data) => {
      state.corporateList = data;
    },
    SET_CURRENT_CORPORATE: (state, data) => {
      state.currentCorporate = data;
    },
  },

  actions: {
    async getCorporateList({ commit, dispatch }, query) {
      try {
        const { data } = await getCorporateList(
          query?.page || 1,
          query?.pageSize || 10,
          query?.search || '',
          query?.sortBy || '',
          query?.orderBy || ''
        );
        commit('SET_CORPORATE_LIST', data.data);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async createCorporate({ dispatch }, corporate) {
      try {
        const response = await createCorporate(corporate);
        if (response?.status === 200) {
          dispatch(
            'notifications/displayNotification',
            {
              message: `Added ${response?.data?.corporateName}`,
              type: 'success',
            },
            { root: true }
          );
        }
        return response?.data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getCorporateProfile({ dispatch }, id) {
      try {
        const { data } = await getCorporateProfile(id);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async getCorporateVendors({ dispatch }, payload) {
      const {
        id,
        page = 1,
        pageSize = 10,
        search = '',
        sortBy = '',
        orderBy = '',
      } = payload;

      try {
        const { data } = await getCorporateVendors(
          id,
          page,
          pageSize,
          search,
          sortBy,
          orderBy
        );
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message || statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCorporateFacilities({ dispatch }, payload) {
      const {
        id,
        page = 1,
        pageSize = 10,
        search = '',
        sortBy = '',
        orderBy = '',
      } = payload;
      try {
        const { data } = await getCorporateFacilities(
          id,
          page,
          pageSize,
          search,
          sortBy,
          orderBy
        );
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message || statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCorporateDocuments({ dispatch }, payload) {
      const {
        id,
        page = 1,
        pageSize = 10,
        search = '',
        sortBy = '',
        orderBy = '',
      } = payload;
      try {
        const { data } = await getCorporateDocuments(
          id,
          page,
          pageSize,
          search,
          sortBy,
          orderBy
        );
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message || statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async editCorporate({ dispatch }, body) {
      try {
        const data = await editCorporate(body?.corporate, body?.id);

        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: `Updated corporate details`, type: 'success' },
            { root: true }
          );
        }

        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getCorporateOverview({ dispatch }, id) {
      try {
        const { data } = await getCorporateOverview(id);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getAllVerifiedCorporate({ dispatch }) {
      try {
        const { data } = await getAllVerifiedCorporate();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getUnVerifiedCorporate({ dispatch }) {
      try {
        const { data } = await getUnVerifiedCorporate();
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async verifyCorporate({ dispatch }, id) {
      try {
        const data = await verifyCorporate(id);
        if (data.status === 200) {
          dispatch(
            'notifications/displayNotification',
            { message: 'Coporate verified', type: 'success' },
            { root: true }
          );
        }
        return data;
      } catch (error) {
        const { message, statusText } = error;

        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },

    async mergeCorporate({ dispatch }, payload) {
      try {
        const response = await mergeCorporate(
          payload.verifiedProfileId,
          payload.unverifiedProfileIds
        );
        if (response.status === 200) {
          const { data } = response;
          if (data.message) {
            dispatch(
              'notifications/displayNotification',
              { message: data.message, type: 'success' },
              { root: true }
            );
          }
        }
        return response;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getSimilarUnVerifiedCorporate({ dispatch }, verifiedProfileId) {
      try {
        const { data } = await getSimilarUnVerifiedCorporate(verifiedProfileId);
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
    async getSimilarVerifiedCorporate({ dispatch }, payload) {

      try {
        const { data } = await getSimilarVerifiedCorporate(
          payload.unverifiedProfileId,
          payload.similarityThreshold
        );
        return data;
      } catch (error) {
        const { message, statusText } = error;
        dispatch(
          'notifications/displayNotification',
          { message: message ? message : statusText, type: 'danger' },
          { root: true }
        );
      }
    },
  },
};
